// // npm modules
import 'whatwg-fetch';
import 'objectFitPolyfill/dist/objectFitPolyfill.min';
import domReady from './helpers/domReady';


domReady(() => {
  objectFitPolyfill();
  let homegame = {
    
    init: () => {

      homegame.locateIP();
      homegame.sportList();
      homegame.eventHandlers();
      homegame.sportsBooking();
      homegame.about();

      
    },

    sportsBooking: () => {
      let body = document.querySelector('body');
      let secBooking = document.querySelector('.venue-details');

      if(secBooking) {
        let booking = document.querySelector('section.venue-booking');
        let bookingContent = document.querySelector('section.venue-booking .venue-booking-content');
        let datePicker = document.querySelector('.venue-booking-content-section .pick-date input');
        let reserveBTNs = Array.from(document.querySelectorAll('.sport-head-reserve'));
        let bookingCloseButton = document.querySelector('.venue-booking-close');
        let bookingSports = Array.from(document.querySelectorAll('.venue-booking-content-section .available-sports-single'));
        
        let bookingSubmitActive = document.querySelector('.venue-booking-content-continue button');

        let bookingTimeSelect = Array.from(document.querySelectorAll('.venue-booking-content-section .available-times .available-times-slot .item'));

        let bookingVenueImages = document.querySelector('.venue-images');
        let bookingVenueImagesCloseBtn = document.querySelector('.booking-venue-images-content .venue-images-close');
        // let timeOptionsEls = Array.from(document.querySelectorAll('select.time-select'));

        
        // let timeSelectOptions = [
        //   '--',
        //   '9:00am',
        //   '9:30am',
        //   '10:00am',
        //   '10:30am',
        //   '11:00am',
        //   '11:30am',
        //   '12:00pm',
        //   '12:30pm',
        //   '1:00pm',
        //   '1:30pm',
        //   '2:00pm',
        //   '2:30pm',
        //   '3:00pm',
        //   '3:30pm',
        //   '4:00pm',
        //   '4:30pm',
        //   '5:00pm',
        //   '5:30pm'
        // ];



        // timeOptionsEls.forEach( (selectEl) => {

        //   timeSelectOptions.forEach((timeslot) => {
  
        //     let createOption = document.createElement('option');
        //     createOption.innerHTML = timeslot;
        //     selectEl.append(createOption);
        //   });
  
        // });



        async function BookingMap(venues) {
          const { Map } = await google.maps.importLibrary("maps");
  
          let bookmap = new Map(document.getElementById("venue-map"), {
            center: { lat: 40.713135377133185, lng: -74.08911770000103 },
            zoom: 14,
            mapId: '97b6ce8dbdb5af6e',
            gestureHandling: 'greedy',
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false
          });
          
        }

        //forces today's date
        datePicker.value = '1';

        let bookingVenueImageModal =  function() {

          let bookingVenueModalImage = document.querySelector('.booking-venue-images-content .grid-images');

          let getBGImageURL = function(img) {
            let style = img.currentStyle || window.getComputedStyle(img, false);
            let bgURL = style.backgroundImage.slice(4, -1).replace(/"/g, "");

            return bgURL;
          }

          let largeImage = bookingVenueImages.querySelector('.venue-images-large');
          let gridImages = Array.from(bookingVenueImages.querySelectorAll('.venue-images-grid .venue-images-grid-single'));
          let venueImages = [largeImage, ...gridImages];

          venueImages.forEach( (el) => {

            let imageURL = getBGImageURL(el);
            let createImage = document.createElement('div');
            createImage.classList.add('venue-image');
            createImage.innerHTML = `<img src="${imageURL}"/>`;

            bookingVenueModalImage.append(createImage);

          });

        }

        let bookingVenueImagesClose = function() {
          let venueImages = document.querySelector('.booking-venue-images');
          let venueImagesContent = document.querySelector('.booking-venue-images-content');
          let bookingVenueModalImage = document.querySelector('.booking-venue-images-content .grid-images');
          let body = document.querySelector('body');

          venueImages.classList.remove('show-animate');
          venueImagesContent.classList.remove('show-animate');
   
         setTimeout( function(){
          body.classList.remove('no-scroll');
          venueImages.classList.remove('show');
          bookingVenueModalImage.innerHTML = '';
         },500)
        }

        bookingVenueImagesCloseBtn.addEventListener('click', () => {
          bookingVenueImagesClose();
        });

        bookingVenueImages.addEventListener('click', () => {
          
          let venueImages = document.querySelector('.booking-venue-images');
          let venueImagesContent = document.querySelector('.booking-venue-images-content');
          let body = document.querySelector('body');

          body.classList.add('no-scroll');
          venueImages.classList.add('show');

          setTimeout( function(){
            venueImages.classList.add('show-animate');
            venueImagesContent.classList.add('show-animate');
          },0)

          bookingVenueImageModal();
        });



        reserveBTNs.forEach((btn) => {

          btn.addEventListener('click', () => {

             body.classList.add('no-scroll');
             booking.classList.add('show');
      
            setTimeout( function(){
              booking.classList.add('show-animate');
              bookingContent.classList.add('show-animate');
            },0)

          });

        });

        booking.addEventListener('click',(e, element) => {
          if(e.target.classList.contains('venue-booking','show','show-animate')){
            bookingModalClose();
          } else {
            e.stopPropagation();
          }
  
        });



        let unSelectSport = function() {
          bookingSports.forEach( (btn) => {
            if(btn.classList.contains('selected')) {
              btn.classList.remove('selected');
            }
          })
        }

        let bookingOpenTimes = function(toOpen = true) {
          let bookingTimes = document.querySelector('.available-times-slot');
          let bookingHolder = bookingTimes.parentNode;

          if(toOpen) {
            bookingHolder.style.height = `${bookingTimes.offsetHeight}px`;
          } else {
            bookingHolder.style.height = `0px`;
          }




        };

        bookingSports.forEach( (btn) => {

          btn.addEventListener('click', function(e) {

            if(!btn.classList.contains('selected')){
              unSelectSport();
              bookingOpenTimes();
              btn.classList.add('selected');
            } else {
              bookingOpenTimes(false);
              btn.classList.remove('selected');
            }

          });

        });

        let bookingTimeSelectDisable = function(renabled = false) {
          bookingTimeSelect.forEach( (btn) => {

            if(renabled) {
              if(btn.classList.contains('disabled')) {
                btn.classList.remove('disabled');
              }
            } else {
              if(!btn.classList.contains('selected')) {
                btn.classList.add('disabled');
              }
            }


          });
        }


        bookingTimeSelect.forEach( (btn) => {

          let btnCopy = btn.querySelector('button');

          btn.addEventListener('click', function(){

            if(!btn.classList.contains('selected')) {
              btn.classList.add('selected');
              btnCopy.innerHTML = 'Selected';
              bookingSubmitActive.classList.add('active');
              bookingTimeSelectDisable();
            }else {
              btn.classList.remove('selected');
              btnCopy.innerHTML = 'Select';
              bookingSubmitActive.classList.remove('active');
              bookingTimeSelectDisable(true);
            }

          });

        });

        bookingSubmitActive.addEventListener('click', function(e) {

          if(e.target.classList.contains('active')) {
            window.location.href = "/checkout.html";
          }

        });

        
        let datepicker = new Datepicker(datePicker, {
          autohide: true,
          format: 'DD, MM d',
          showDaysOfWeek: true,
          showOnClick: true,
          showOnFocus: true,
          updateOnBlur: true
        }); 



        let bookingModalClose = function() {
          booking.classList.remove('show-animate');
          bookingContent.classList.remove('show-animate');
   
         setTimeout( function(){
          body.classList.remove('no-scroll');
          booking.classList.remove('show');


          bookingSubmitActive.classList.remove('active');

          bookingTimeSelect.forEach( (btn) => {
 
           let btnCopy = btn.querySelector('button');
 
            if(btn.classList.contains('selected')) {
              btn.classList.remove('selected');
              btnCopy.innerHTML = 'Select';
            }
 
            if(btn.classList.contains('disabled')) {
              btn.classList.remove('disabled');
            }
 
          });


         },500)

        }

        bookingCloseButton.addEventListener('click', () => {
          bookingModalClose();
        });


        // let timeStartOpts = Array.from(document.querySelectorAll('.venue-booking-content-section .pick-time .pick-time-start .time-select option'));
        // let timeEndOpts = Array.from(document.querySelectorAll('.venue-booking-content-section .pick-time .pick-time-end .time-select option'));
        // // console.log(timeEndOpts)

        // let timeStartOptsSelect = document.querySelector('.venue-booking-content-section .pick-time .pick-time-start .time-select');
        // let timeEndOptsSelect = document.querySelector('.venue-booking-content-section .pick-time .pick-time-end .time-select');

        // timeStartOptsSelect.addEventListener('change', (option) => {
        //   let index = timeStartOptsSelect.selectedIndex;
        //     alert(`${option.target.value} ${index}`);
          
        // });

        BookingMap();
          

      }

    },

    sportListAllFilters: (sports) => {
      let sportListing = Object.entries(sports['sports']);
      let sportsContainer = document.querySelector('.all-filters .all-filters-content-activities-holder');
      let sportListViewMore = document.querySelector('.all-filters-content-activities-single.viewmore');
      let datePickerFilter = document.querySelector('.filter.date-select');
      let timeOptionsEls = Array.from(document.querySelectorAll('select.time-select'));
    
      let sportListInitial = sportListing.filter( (sport, i) => i < 13);
      let sportListAdditional = sportListing.filter( (sport, i) => i > 12);

      //forces today's date
      datePickerFilter.value = '1';

      let timeSelectOptions = [
        '--',
        '9:00 am',
        '9:30 am',
        '10:00 am',
        '10:30 am',
        '11:00 am',
        '11:30 am',
        '12:00 pm',
        '12:30 pm',
        '1:00 pm',
        '1:30 pm',
        '2:00 pm',
        '2:30 pm',
        '3:00 pm',
        '3:30 pm',
        '4:00 pm',
        '4:30 pm',
        '5:00 pm',
        '5:30 pm'
      ];


      timeOptionsEls.forEach( (selectEl) => {

        timeSelectOptions.forEach((timeslot) => {

          let createOption = document.createElement('option');
          createOption.innerHTML = timeslot;
          selectEl.append(createOption);
        });

      });

      let addSportTile = function(sport, after = false, initial) {
        let sportIconContainer = document.createElement('div');
        sportIconContainer.classList.add('all-filters-content-activities-single');
        sportIconContainer.setAttribute('data-sport',sport[0]);

        if(initial > 12) {
          sportIconContainer.classList.add('additional');
        }

        let sportIcon = document.createElement('div');
        sportIcon.classList.add('all-filters-content-activities-single-icon')

        sportIcon.innerHTML = `
          <svg class="icon icon-${sport[0]}">
                <use class="icon--${sport[0]}" xlink:href="#${sport[1].icon}"></use>
          </svg>
        `;


        let sportLabel = document.createElement('div');
        sportLabel.classList.add('all-filters-content-activities-single-label');
        sportLabel.innerHTML = sport[1].label;

        sportIconContainer.append(sportIcon);
        sportIconContainer.append(sportLabel);


        if(after){
          sportsContainer.append(sportIconContainer);
        } else {
          sportsContainer.prepend(sportIconContainer);
        }
        
      }

      //sportListInitial.forEach(sport => addSportTile(sport));
      sportListing.forEach((sport,i) => addSportTile(sport, false, i));

      sportListViewMore.addEventListener('click', () => {
        sportListViewMore.classList.add('added');
        sportListAdditional.forEach(sport => addSportTile(sport, true));
      });


      let datepickerFilter = new Datepicker(datePickerFilter, {
        autohide: true,
        format: 'DD, MM d',
        showDaysOfWeek: true,
        showOnClick: true,
        showOnFocus: true,
        updateOnBlur: true
      }); 



    },
    //this also sends list to render sportlist for filters
    sportList: () => {  
      let sportsContainerParent = document.querySelector('.filters-sports-type');
      let sportsContainer = document.querySelector('.filters-sports-type .filters-sports-type-holder');
      let signupModalSports = document.querySelector('.sign-up-select-container select');
      

      let renderSports = function(sports){
      
        let sportListing = Object.entries(sports['sports']);
        
        let sportDTScroll = function() {
          let homePage = document.querySelector('body.home');
          let navScrollBtnRight = document.querySelector('.filters-sports-type-button img');
          let navScrollBtnLeft = document.querySelector('.filters-sports-type-curtain img');

          if(homePage) {
            let quick_filter = $('.filters-sports-type-holder');
            let quick_filter_setting = {
              dots: false,
              infinite: true,
              speed: 100,
              slidesToShow: 4,
              slidesToScroll: 4,
              centerMode: true,
              variableWidth: true,
              arrows: true,
              nextArrow: navScrollBtnRight,
              prevArrow: navScrollBtnLeft
            };

            quick_filter.slick(quick_filter_setting);

            let navFilterBtns = Array.from(document.querySelectorAll('.filters-sports-type-single'));
            navFilterBtns.forEach( (btn) => {
              homegame.eventNavSingleFilter(btn);
            });
            
          }

        }


        
        sportListing.forEach( (sport, i) => {

          let sportIconContainer = document.createElement('div');
          let signUpOption = document.createElement('option');
          sportIconContainer.classList.add('filters-sports-type-single');
          sportIconContainer.setAttribute('data-sport',sport[0]);

          let sportIcon = document.createElement('div');
          sportIcon.classList.add('filters-sports-type-single-icon')

          sportIcon.innerHTML = `
            <svg class="icon icon-${sport[0]}">
                  <use class="icon--${sport[0]}" xlink:href="#${sport[1].icon}"></use>
            </svg>
          `;

          let sportLabel = document.createElement('div');
          sportLabel.classList.add('filters-sports-type-single-label');
          sportLabel.innerHTML = sport[1].label;
          signUpOption.innerHTML = sport[1].label;

          sportIconContainer.append(sportIcon);
          sportIconContainer.append(sportLabel);
          signupModalSports.append(signUpOption);
          // adding label to last icon added on nav filter
          if(i == 0) {sportIconContainer.classList.add('end');}

          

          sportsContainer.prepend(sportIconContainer);

          if(i == (sportListing.length - 1)) {
            sportDTScroll();
          }

        });
        
       
      }
      
      let getSports = async function() {
        const response = await fetch("data/sports.list.json");
        const sports = await response.json();
        renderSports(sports);
        homegame.sportListAllFilters(sports);
      }

      if(sportsContainer) {
        getSports();
      }
      

    },

    venueView: (whichVenueView) => {
      let venueViewType = Array.from(document.querySelectorAll('.venue.view'));

      venueViewType.forEach( (venueView) => {
        venueView.classList.remove('active');

        if(venueView.classList.contains(whichVenueView)) {
          venueView.classList.add('active');
        }

      });

    },

    signUpModal: () => {
      
      let signUp = document.querySelector('section.sign-up');
      let signUpContent = document.querySelector('section.sign-up .sign-up-content');
      signUp.classList.add('show');
      setTimeout( function(){
        signUp.classList.add('show-animate');
        signUpContent.classList.add('show-animate');
      },0)

    },

    signUpModalClose: () => {
      let signUp = document.querySelector('section.sign-up');
      let signUpContent = document.querySelector('section.sign-up .sign-up-content');
      signUp.classList.remove('show-animate');
      signUpContent.classList.remove('show-animate');
      setTimeout( function(){
        signUp.classList.remove('show');
      },500)
    },

    allFiltersModal: () => {
      let filters = document.querySelector('section.all-filters');
      let filtersContent = document.querySelector('section.all-filters .all-filters-content');
      let body = document.querySelector('body');

      body.classList.add('no-scroll');
      filters.classList.add('show');
      setTimeout( function(){
        filters.classList.add('show-animate');
        filtersContent.classList.add('show-animate');
      },0)
    },

    allFiltersModalClose: () => {
      let filters = document.querySelector('section.all-filters');
      let filtersContent = document.querySelector('section.all-filters .all-filters-content');
      let body = document.querySelector('body');

      body.classList.remove('no-scroll');
      filters.classList.remove('show-animate');
      filtersContent.classList.remove('show-animate');
      setTimeout( function(){
        filters.classList.remove('show');
      },500)
    },
    //**************************//
    //**************************//
    eventNavSingleFilter: (btn) => {

      

      let deSelectBtns = function() {

        let btnRest = Array.from(document.querySelectorAll('.filters-sports-type-single'));
        btnRest.forEach((btn) => {

          if(btn.classList.contains('selected')) {
            btn.classList.remove('selected');
          }
  
        });

      }

      let filterTrigger = function(sport) {

        let allFiltersClear = document.querySelector('.all-filters-content-submit button.clear');
        let filterSubmitBTN = document.querySelector('.all-filters-content-submit button.results');
        let modalFilters = Array.from(document.querySelectorAll('.all-filters-content-activities-holder .all-filters-content-activities-single'));

        modalFilters.forEach( (filter) => {

          if(filter.dataset.sport == sport) {
            allFiltersClear.dispatchEvent(new Event('click'));
            
            setTimeout(function(){

              filter.dispatchEvent(new Event('click'));
              filterSubmitBTN.dispatchEvent(new Event('click'));

            },0)

          }

        });
        

      }

      let dupeFilter = function(sport, add = true) {

        let btnRest = Array.from(document.querySelectorAll('.filters-sports-type-single'));

        btnRest.forEach( (btn) => {

          if(sport == btn.dataset.sport) {

            if(add) {
              btn.classList.add('selected');
            }else {
              btn.classList.remove('selected');
            }

          }

        });

      }

      let singleFilterClick = function(btn) {

        let allFiltersClear = document.querySelector('.all-filters-content-submit button.clear');

        btn.addEventListener('click', (e) => {

          if(!btn.classList.contains('selected')) {
            deSelectBtns();
            dupeFilter(btn.dataset.sport, true);
            filterTrigger(btn.dataset.sport);
          }else {
            allFiltersClear.dispatchEvent(new Event('click'));
            dupeFilter(btn.dataset.sport, false);
            
          }
  
        });
        
      }

      singleFilterClick(btn);


    },
    //**************************//
    //**************************//
    eventSelectedToggle: (btn) => {
      btn.addEventListener('click', (e) => {

        if(!btn.classList.contains('selected')) {
          btn.classList.add('selected');
        }else {
          btn.classList.remove('selected');
        }

      });
    },
    //**************************//
    //**************************//
    eventHandlers: (btn) => {
      // venueViewTypeButtons are the buttons which change venue map view or venue map list.
      let venueViewTypeButtons = Array.from(document.querySelectorAll('.filters-map-list .icon'));

      // sign up: buttons
      // desktop button
      let signUpDtButton = document.querySelector('.nav--link-right .sign-up-btn');
      // sign close modal
      let signUpCloseButton = document.querySelector('section.sign-up');

      venueViewTypeButtons.forEach((viewButton) => {
        viewButton.addEventListener('click', () => {
          if(!viewButton.classList.contains('active')) {
            let currentView = viewButton.parentNode.querySelector('.icon.active');
            let newView = viewButton.getAttribute('data-view');
            currentView.classList.remove('active');
            viewButton.classList.add('active');
            homegame.venueView(newView);
          }
        });
      });

      signUpDtButton.addEventListener('click', (e) => {
        e.preventDefault();
        homegame.signUpModal();
      });
      signUpCloseButton.addEventListener('click',(e, element) => {
        if(e.target.classList.contains('sign-up','show','show-animate')){
          homegame.signUpModalClose();
        } else {
          e.stopPropagation();
        }

      });
    },

    sportsMap: (userCoords) => {

      let map;
      let service;
      let mapset = false;

      let searchInput = document.querySelector('.filters-search-input');
      let venueListingContainer = document.querySelector('.venue-list .venue-list-content');

      let mapInfoPanels = [];
      let mapMarkers = [];
      let theVenues = [];


      let sportFilters = [];

      let sportListAllFiltersEvents = function() {

        let sportsBTNs = Array.from(document.querySelectorAll('.all-filters-content-activities-single:not(.viewmore)'));
        let filterSubmitBTN = document.querySelector('.all-filters-content-submit button.results');
        // all ilters: buttons
        let allFiltersDtButton = document.querySelector('.filters .filters-all-filter');
        let allFiltersCloseButtonOne = document.querySelector('section.all-filters');
        let allFiltersCloseButtonTwo = document.querySelector('.all-filters-content-top-bar .icon-close');
        let allFilterMbButton = document.querySelector('.filters .filters-search-filter');
        
        //Venue distance range
        let venueRange = document.querySelector('.all-filters-content-distance-range input');
        //Venue distance range label
        let venueRangeLabel = document.querySelector('.miles-range-input');

        //Venue availability toggle selection
        let venueAvail = Array.from(document.querySelectorAll('.all-filters-content-availability-content .toggle'));
        //Venue Type toggle selections
        let venueType = Array.from(document.querySelectorAll('.all-filters-content-type-content button'));
        //Venue Details toogle selections
        let venueDetails = Array.from(document.querySelectorAll('.all-filters-content-details-content button'));
        //Playing Experience toggle selections
        let playingExp = Array.from(document.querySelectorAll('.all-filters-content-experience-content button'));
        
        let filterToggles = [...venueDetails, ...playingExp, ...venueType, ...venueAvail];

        let allFiltersClear = document.querySelector('.all-filters-content-submit button.clear');

        let filterStartTime = document.querySelector('.all-filters-content-date-time .start-time .time-select');
        let filterEndTime = document.querySelector('.all-filters-content-date-time .end-time .time-select');

        let filterMinPrice = document.querySelector('.all-filters-content-price-range .min-price input');
        let filterMaxPrice = document.querySelector('.all-filters-content-price-range .max-price input');

        // ['input','onchange'].forEach((eventType) => {

        //   venueRange.addEventListener(eventType, () => {
        //     console.log(venueRange.parentNode.offsetWidth);
        //     venueRangeLabel.style.left = `${((venueRange.value*2)*.01)*venueRange.parentNode.offsetWidth}px`;
        //     venueRangeLabel.style.transform=`translateX(-${venueRange.value*2}%)`;
        //     venueRangeLabel.innerHTML = `${venueRange.value} mi`;
        //   });

        // });

        filterToggles.forEach((btn) => {

          homegame.eventSelectedToggle(btn);

        });

        allFiltersDtButton.addEventListener('click', (e) => {
          e.preventDefault();
          homegame.allFiltersModal();
        });

        allFilterMbButton.addEventListener('click', (e) => {
          e.preventDefault();
          homegame.allFiltersModal();
        });
  
        allFiltersCloseButtonOne.addEventListener('click', (e) => {
          if(e.target.classList.contains('all-filters','show','show-animate')){
            homegame.allFiltersModalClose();
          } else {
            e.stopPropagation();
          }
        });
  
        allFiltersCloseButtonTwo.addEventListener('click', (e) => {
          e.preventDefault();
          homegame.allFiltersModalClose();
        });

        allFiltersClear.addEventListener('click',(e) => {
          clearFilters();
        });

        let removeDuplicates = function(arr) {
          return [...new Set(arr)];
        }

        //validate time filter option
        let getTimeFilter = function(){

          if (filterStartTime.value != '--' && filterEndTime.value != '--') {
            return true;
          } else if(filterStartTime.value == '--' && filterEndTime.value == '--') {
            return false;
          } else if(filterStartTime.value == '--' && filterEndTime.value != '--') {
            return false;
          }

        }

        let getPriceFilter = function() {
          //check for money range
          if(filterMinPrice.value != "" && filterMaxPrice.value != "") {
            return true;
          } else if(filterMinPrice.value == "" && filterMaxPrice.value != "") {
            return true;
          }
        }

        //get venues
        let getFilteredVenues = function(arr, timeFilter = false, priceFilter = false) {
          let tmp = [];


          let dateObj = function(d) {
             
              let parts = d.split(/:|\s/);
              let  date  = new Date();
              if (parts.pop().toLowerCase() == 'pm') parts[0] = (+parts[0]) + 12;
              date.setHours(+parts.shift());
              date.setMinutes(+parts.shift());
              return date;
          }


          //filter based on sport
          arr.forEach( (sport) => {
            theVenues.filter( (venue) => {

              Object.entries(venue.sports).forEach( (single) => {

                if(single[0] == sport) {

                  venue.type = sport;

                  tmp.push(venue);

                }
  
              });
  
            });

          });
          
          //filter based on time
          let venueTimeRange = tmp.filter((venue) => {

            if(timeFilter) {
              let venueOpen = dateObj(venue.open);
              let venueClose = dateObj(venue.close);
              let userOpen = dateObj(filterStartTime.value);
              let userClose = dateObj(filterEndTime.value);
  
              if(userClose <= venueClose && userOpen >= venueOpen) {
                return venue;
              }
            } else {
              return venue;
            }

            
          });

          //filter based on price
          let venuePriceRange = venueTimeRange.filter((venue) => {

            if(priceFilter){
              let venueMin = venue.pricemin;
              let venueMax = venue.pricemax;
              let userMin = filterMinPrice.value;
              let userMax = filterMaxPrice.value;

              let onlyMax = userMin == "" && userMax != "" ? true : false;

              if(onlyMax) {
                if(userMax <= venueMax) {
                  return venue;
                }
              } else {
                if(userMin >= venueMin && userMax <= venueMax){
                  return venue;
                }
              }

            } else {
              return venue;
            }

          });
          
          return(removeDuplicates(venuePriceRange));
        }
        
        if(!mapset) {
          mapset = true;

          sportsBTNs.forEach( (btn) => {

            btn.addEventListener('click', () => {
              let sportFilterSelected = btn.getAttribute('data-sport');
              
              
              if(!btn.classList.contains('selected')) {
                btn.classList.add('selected');
                sportFilters.push(sportFilterSelected);
              } else {
                btn.classList.remove('selected');
  
                if(sportFilters.includes(sportFilterSelected)) {
                  let filterIndex = sportFilters.indexOf(sportFilterSelected);
                  sportFilters.splice(filterIndex, 1);
                }
  
              }
            
            });
  
          });
          
          //modal Allfilter search submit
          filterSubmitBTN.addEventListener('click', () => {
            venueMarkerDelete();
            if(sportFilters.length > 0) {
              //listing under search input
              venueListingContainer.innerHTML = "";

              venueMarkes(getFilteredVenues(sportFilters,getTimeFilter(),getPriceFilter()));
              sportFilters.length = 0;
              
            }
            
            allFiltersCloseButtonTwo.dispatchEvent(new Event('click'));
          });
        }


      };
      
      // handles click from live search results
      let searchResultClick = function(resultClicked) {
        let resultLatLon = resultClicked.getAttribute('data-latlon');


        mapMarkers.forEach((marker) => {
          let markerLatLon = marker.getPosition().lat()+marker.getPosition().lng();
          
          if(resultLatLon == markerLatLon) {
            google.maps.event.trigger(marker, 'click');
            map.setCenter(marker.getPosition());
            searchInput.value = '';
            searchResultRender('');
          }
          
        });

      }


      let clearFilters = function() {
        let sportsBTNs = Array.from(document.querySelectorAll('.all-filters-content-activities-single:not(.viewmore)'));

        sportsBTNs.forEach((btn) => {
          if(btn.classList.contains('selected')) {
            btn.classList.remove('selected');
          }
        });
        
        let venueResetList = theVenues.map((venue) => {
          Object.entries(venue.sports).forEach( (single) => {
            venue.type = single[0];

          });
          return venue;
        });

        venueMarkerDelete();
        venueListingContainer.innerHTML = "";
        venueMarkes(venueResetList);
        homegame.allFiltersModalClose();

      }


      // renders results from live search under input
      let searchResultRender = function(venue) {

        let resultContainer = searchInput.parentNode.querySelector('.filters-search-result-listing');
        
        if(venue.length > 0){
          searchInput.parentNode.classList.add('with-results');
          resultContainer.classList.add('active');
          resultContainer.innerHTML = '';
          
          let markerLatLng = mapMarkers.map((marker) => {
            return marker.getPosition().lat()+marker.getPosition().lng();
          });

          let newDropMarker = venue.filter((v) => {
            if(!markerLatLng.includes(v.location.lat+v.location.lon)) {
              return v;
            }
          });

          let currentMarkerDropped = venue.filter((v) => {
            if(markerLatLng.includes(v.location.lat+v.location.lon)) {
              return v;
            }
          });

          newDropMarker.forEach( (listing) => {
            let li = document.createElement('li');
            li.setAttribute('data-latlon',listing.location.lat+listing.location.lon);
            li.innerHTML = listing.title;

            li.addEventListener('click',(el) => {

              venueMarkes([listing],true,el.target);
              
            });

            resultContainer.append(li);
          });
          

          currentMarkerDropped.forEach( (listing) => {
            let li = document.createElement('li');
            li.setAttribute('data-latlon',listing.location.lat+listing.location.lon);
            li.innerHTML = listing.title;

            li.addEventListener('click',(el) => {
              
              searchResultClick(el.target);  
              
            });

            resultContainer.append(li);
          });


        }else { 
          searchInput.parentNode.classList.remove('with-results');
          resultContainer.classList.remove('active');
        }



      }
      // live search on venues from data
      let searchNameSearch = function(substr) {

          
          let subArr = theVenues.filter( (venue) => {
            let str = venue.title.toLowerCase();
            
            if(str.includes(substr)){
 
              return venue;

            }

          });
          
          return subArr;

      }

      let searchOnEnter = function(str) {
        let service = new google.maps.places.PlacesService(map);

        var request = {
          query: str,
          fields: ['name', 'geometry'],
        };

        service.findPlaceFromQuery(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            for (let i = 0; i < results.length; i++) {
              console.log(results[i])
              // console.log(results[i].geometry.location.lat());
              // console.log(results[i].geometry.location.lng());
            }
      
            map.setCenter(results[0].geometry.location);
          }
        });

      }

      // enables search input
      let searchActive = function() {
        searchInput.classList.add('active');

        searchInput.addEventListener("keyup", (event) => {
          if (event.key === "Enter") {
            
            if(searchInput.value === "") {
              searchInput.parentNode.classList.add('error');
            } else {
              searchInput.parentNode.classList.remove('error');
              searchOnEnter(searchInput.value);
            }

          }

          if(searchInput.value.length > 2) {
            let searchResults = searchNameSearch(searchInput.value);
            searchResultRender(searchResults);
          } else {
            searchResultRender('');
          }


        });

      }

      let createInfoPanel = function(vName,vID,vImage) {

        let cardImage = vImage.length == 0 ? './assets/images/venue-1.jpg' : `./assets/images/venue-details/cards/${vImage}`


        let infoPanel = `
          <div id="content">
            <div id="siteNotice"></div>
            
              <div class="content-info">

                <div class="content-info-image">
                  <img src="${cardImage}" alt="">
                </div>

                <div class="content-info-body">
                  <h2 class="content-info-name">${vName}</h2>

                  <div class="content-info-stats">
                    <div>
                      <svg class="icon icon-star">
                        <use class="icon--star" xlink:href="#Star"></use>
                      </svg>
                      4.5
                    </div>
                    <div>
                      <svg class="icon icon-sport">
                        <use class="icon--sport" xlink:href="#Sport"></use>
                      </svg>
                      Playing now
                    </div>
                    <div>
                      <svg class="icon icon-money">
                        <use class="icon--money" xlink:href="#Money"></use>
                      </svg>
                      $0-$25
                    </div> 
                  </div>

                  <div class="content-info-sports">
                    <span>
                      <svg class="icon icon-soccer">
                        <use class="icon--soccer" xlink:href="#Soccer"></use>
                      </svg>
                      Soccer
                    </span>
                    <span>
                      <svg class="icon icon-skatepark">
                        <use class="icon--skatepark" xlink:href="#SkatePark"></use>
                      </svg>
                      Skate Park
                    </span>
                    <span>
                      <svg class="icon icon-tennis">
                        <use class="icon--tennis" xlink:href="#Tennis"></use>
                      </svg>
                      Tennis
                    </span>
                    <span>
                      <svg class="icon icon-basketball">
                        <use class="icon--basketball" xlink:href="#Basketball"></use>
                      </svg>
                      Basketball
                    </span>
                    <span class="additional">+X</span>
                  </div>

                  <a href="venue.html" class="content-info-venue" data-venue="${vID}" data-venue="${vName}">View details</a>
                </div>

              </div>
            
          </div>
        `;


        return infoPanel;
      }

      // created Venue card and appends to view
      let createVenueListingCard = function(vName,vImage, vId, vSports) {

        let cardImage = vImage.length == 0 ? './assets/images/venue-1.jpg' : `./assets/images/venue-details/cards/${vImage}`;

        let venueCard = document.createElement('a');
        venueCard.classList.add('venue-list-content--single');
        venueCard.setAttribute('href',`./venue.html?id=${vId}`);
        venueCard.innerHTML = `
            <div class="venue-list-content--single-image">
              <img src="${cardImage}" alt=""/>
            </div>

            <div class="venue-list-content--single-info">
              <h2 class="venue-list-content--single-name">${vName}</h2>
              <div class="venue-list-content--single-stats">
                <div>
                  <svg class="icon icon-star">
                    <use class="icon--star" xlink:href="#Star"></use>
                  </svg>
                  4.5
                </div>
                <div>
                  <svg class="icon icon-sport">
                    <use class="icon--sport" xlink:href="#Sport"></use>
                  </svg>
                  Playing now
                </div>
                <div>
                  <svg class="icon icon-money">
                    <use class="icon--money" xlink:href="#Money"></use>
                  </svg>
                  $0-$25
                </div>                  </div>
              <div class="venue-list-content--single-sports">
                <span>
                  <svg class="icon icon-soccer">
                    <use class="icon--soccer" xlink:href="#Soccer"></use>
                  </svg>
                  Soccer
                </span>
                <span>
                  <svg class="icon icon-skatepark">
                    <use class="icon--skatepark" xlink:href="#SkatePark"></use>
                  </svg>
                  Skate Park
                </span>
                <span>
                  <svg class="icon icon-tennis">
                    <use class="icon--tennis" xlink:href="#Tennis"></use>
                  </svg>
                  Tennis
                </span>
                <span>
                  <svg class="icon icon-basketball">
                    <use class="icon--basketball" xlink:href="#Basketball"></use>
                  </svg>
                  Basketball
                </span>
                <span class="additional">+X</span>
              </div>
            </div>
        `;
        
        let sportCont = venueCard.querySelector('.venue-list-content--single-sports');
        let sportTotal = Object.keys(vSports).length > 4 ? Object.keys(vSports).length-4 : 0;

        sportCont.innerHTML = "";
        //loops thru sports per venue and adds SVG into card
        //only populating the first 4 sports
        Object.keys(vSports).forEach((key,i) => {
          let value = vSports[key];

          if(i < 4) {
            let venueSport = document.createElement('span');
            venueSport.innerHTML = `
            <svg class="icon icon-${key}">
              <use class="icon--${key}" xlink:href="#${value.icon}"></use>
            </svg>
            ${value.label}
            `;

            sportCont.append(venueSport);
          }

        });
        //if there additional sport, meaning above 4, it will add additional number.
        if(sportTotal > 0){
          let venueAdditional = document.createElement('span');
          venueAdditional.classList.add('additional');
          venueAdditional.innerHTML = `+${sportTotal}`;
          sportCont.append(venueAdditional);
        }

        venueListingContainer.append(venueCard);

      }

      // Sets the map on all markers in the array.
      let setMapOnAll = function (map) {
        for (let i = 0; i < mapMarkers.length; i++) {
          mapMarkers[i].setMap(map);
        }
      }

      // Removes the markers from the map, but keeps them in the array.
      let venueMarkershide = function() {
        setMapOnAll(null);
      }

      // Shows any markers currently in the array.
      let venueMarkershow =function() {
        setMapOnAll(map);
      }
      // Deletes all markers in the array by removing references to them.
      let venueMarkerDelete = function () {
        venueMarkershide();
        mapMarkers = [];
      }

      let venueMarkerIcon = function(venuetype) {
        let iconBaseURL = './assets/images/markers/';

        let iconMarker = (venuetype.length === 0 || venuetype == 'Trampoline') ? `${iconBaseURL}multisport.svg` : `${iconBaseURL}${venuetype.toLowerCase().split(" ").join("")}.svg`;
        return iconMarker;

      }

      // creates markers on map and also calls to create card to view
      let venueMarkes = function(venues, single = false, el = "") {
        
        venues.forEach( (venue, i) => {
          
          let marker = new google.maps.Marker({
            position: new google.maps.LatLng(venue.location.lat, venue.location.lon),
            icon: venueMarkerIcon(venue.type),
            //animation: google.maps.Animation.DROP,
            map: map,
          });

          mapMarkers.push(marker);

          let infowindow = new google.maps.InfoWindow({
            content: createInfoPanel(venue.title,venue.id, venue.image),
            ariaLabel: venue.title,
          });
          //pushes individual infowindow of marker into array
          mapInfoPanels.push(infowindow);

          //adds into venue list container
          createVenueListingCard(venue.title, venue.image, venue.id, venue.sports);
          

          marker.addListener('click', () => {
           //loops thru array of infowindows, if open, it will close it before it opens the new infowindow.
           mapInfoPanels.forEach( (panel) => {
             if(panel.open) {
              panel.close();
             }
           });
           //map.panTo(marker.getPosition());
           //map.panToBounds(marker.getPosition(),100)
           //map.setCenter(marker.getPosition());
            
            infowindow.open({
              anchor: marker,
              map,
            });

           

          });

          if(i == (venues.length-1) && !mapset){
            sportListAllFiltersEvents();
          }

          if(single) {
            searchResultClick(el);
          }

        });

        
      }

      async function initMap(venues) {
        const { Map } = await google.maps.importLibrary("maps");

        map = new Map(document.getElementById("map"), {
          center: { lat: userCoords.lat, lng: userCoords.lon },
          zoom: 14,
          mapId: '97b6ce8dbdb5af6e',
          gestureHandling: 'greedy',
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false
        });
        
        searchActive();
        venueMarkes(venues);

      }



      let getVenues = async function() {
        //const response = await fetch("data/venue.list.json");
        const response = await fetch("data/venues.json");
        const venues = await response.json();
        
        //let venueListing = Object.entries(venues['venues']);
        let venueListing = venues;
        

        //
        venueListing.forEach( (venue, i) => {

          let venueSportsObj = {};
          venue.venueSports.forEach(( venue ) =>{
            let d = {[venue.toLowerCase().split(" ").join("")]:{"label": venue, "icon": venue}};
            console.log(d);
            Object.assign(venueSportsObj, d)

          });

          

          let venueDetails = {
            location: {lat: parseFloat(venue.latitude),lon:parseFloat(venue.longitude)},
            type: venue.primarySport,
            title: venue.venueName,
            sports: venueSportsObj,
            id: venue.venueId,
            image: '',
            open: "9:00 am",
            close: "5:00 pm",
            pricemin: 25,
            pricemax: 150
          }
          
          
          theVenues.push(venueDetails);

          if(i === (venueListing.length - 1)) {
            initMap(theVenues);
          }
          
        });
      }


      getVenues();


    },

    locateIP: () => {
      let theMap = document.querySelector('#map');
      // THIS IS CURRENTLY COMMENTED TO DEFAULT TO HOBOKEN 
      // WE CAN UN-C0MMENT THE BELOW TO DETECT USER LOCATION
      // let userCoords = function() {

      //   return new Promise((res, rej) => {
      //     navigator.geolocation.getCurrentPosition(res, rej);
      //   });
      // }

      // let userLocation = userCoords().then((results) => {

      //   homegame.sportsMap({
      //     lat: results.coords.latitude,
      //     lon: results.coords.longitude
      //   });

      // }).catch((error) => {
      //   //console.log(error.message);

      //   homegame.sportsMap({
      //     lat: 40.74405897293595,
      //     lon: -74.03255553940653
      //   });
      // });
      if(theMap) {
        homegame.sportsMap({
          lat: 40.74405897293595,
          lon: -74.03255553940653
        });
      }


      
      
    },

    about: () => {

      let aboutPage = document.querySelector('body.about');

      if(aboutPage) {

        let the_slider = $('.about-features-phones');
        let the_slider_setting = {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          centerMode: true,
          focusOnSelect: true,
          arrows: false,
          centerPadding: '0px',
          responsive: [
            {
              breakpoint: 99999,
              settings: "unslick"
            },
            {
              breakpoint: 924,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                centerMode: true,
                focusOnSelect: true,
                arrows: false,
                centerPadding: '0px',
              }
            }
          ]
        };
        
        $(document).ready(function(){
          // Init Slider
          the_slider.slick(the_slider_setting);
          // Reslick only if it's not slick()
          $(window).on('resize', function() {
            if (!the_slider.hasClass('slick-initialized')) {
              return the_slider.slick(the_slider_setting);
            }
          });

        });

      }

    }



  }

  homegame.init();

});


